<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="750"
      persistent
    >
      <v-card>
        <v-toolbar dark color="blue darken-2" class="elevation-0 px-3">
          <span class="headline">Asignación de docentes guías</span>
        </v-toolbar>
        <v-card-text>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  color="blue darken-2"
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar..."
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <span
                  class="text-body-1 text-center  "
                  v-if="teachersData.length == 0"
                  >Ningún docente agregado.</span
                >
                <v-chip
                  v-else
                  v-for="item in teachersData"
                  :key="item.id"
                  dark
                  close
                  :color="`blue darken-2`"
                  class="mr-3 mb-3"
                  @click:close="removeTeacher(item.user_id)"
                >
                  <v-avatar left :color="`white`" class="blue--text" size="10">
                    {{
                      teacherData(item.user_id).first_name[0] +
                        teacherData(item.user_id).last_name[0]
                    }}
                  </v-avatar>
                  {{
                    `${teacherData(item.user_id).first_name} ${
                      teacherData(item.user_id).last_name
                    }`
                  }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6">Docentes</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="text-body-1 text-center  "
                  v-if="filteredTeachers.length == 0 && search != ''"
                  >No se ha encontrado ninguna coincidencia</span
                >
                <span
                  class="text-body-1 text-center  "
                  v-else-if="filteredTeachers.length == 0 && !search"
                  >No hay ningún docente registrado</span
                >
                <v-virtual-scroll
                  :items="filteredTeachers"
                  :item-height="50"
                  height="350"
                  v-else
                >
                  <template v-slot:default="{ item }">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                          color="blue darken-2"
                          size="56"
                          class="white--text"
                        >
                          {{ item.first_name[0] + item.last_name[0] }}
                        </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${item.first_name} ${item.last_name}`
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          v-if="!hasBeenSelected(item.id_user)"
                          @click="addTeacher(item)"
                        >
                          Agregar
                          <v-icon color="blue darken-2" right>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          disabled
                          small
                          v-else
                          @click="removeTeacher(item.id_user)"
                        >
                          Ya asignado
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0 pb-3">
          <v-btn text outlined class="mr-1" @click="closeModal">Cancelar</v-btn>
          <v-btn
            color="blue darken-2"
            class="white--text"
            :loading="isLoading"
            depressed
            @click="save()"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoaderModal ref="mdlLoader">
      <template v-slot:title> Cargando docentes e instructores...</template>
    </LoaderModal>
  </div>
</template>
<script>
import { FETCH_USERS } from "@/core/services/store/role.module.js";
import teacherRepository from "@/repositories/teacherRepository.js";
import LoaderModal from "@/components/modals/LoaderModal.vue";
export default {
  name: "SelectTeacherGuide",
  components: {
    LoaderModal,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      teachersData: [],
      savedData: [],
      verifyTechnical: "",
      verifyAcademic: "",
      assignTeacher: {},
      teachersId: [],
      search: "",
    };
  },
  props: {
    academicGroups: {
      type: Object,
    },
    technicalGroups: {
      type: Object,
    },
  },
  methods: {
    getTechnicalGroup(id) {
      this.$refs.mdlLoader.loadingDialog = true;
      teacherRepository
        .getGuideTeacherByTechnicalGroup(id)
        .then(({ data }) => {
          if (data.teacher_guide_groups.length > 0) {
            data.teacher_guide_groups.forEach((element) => {
              this.teachersId.push(element.user_id);
              this.teachersData.push({
                user_id: element.user_id,
                teacher_full_name:
                  element.teacherInformation?.first_name +
                  element.teacherInformation?.last_name,
                teacher_code:
                  element.teacherInformation?.first_name[0] +
                  element.teacherInformation?.last_name[0],
              });
            });
          } else {
            this.teachersData = [];
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.$refs.mdlLoader.loadingDialog = false;
          this.dialog = true;
        });
    },
    updateTechnicalGroup() {
      this.isLoading = true;
      teacherRepository
        .addTeacherToTechnicalGroup(this.technicalGroups.id, {
          user_ids: this.savedData,
        })
        .then(({ data }) => {
          this.sweetAlertResponse({
            status: data.status,
            message: data.message,
          });
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeModal();
        });
    },
    getAcademicGroup(id) {
      this.$refs.mdlLoader.loadingDialog = true;
      teacherRepository
        .getGuideTeacherByAcademicGroup(id)
        .then(({ data }) => {
          if (data.teacher_guide_groups.length > 0) {
            data.teacher_guide_groups.forEach((element) => {
              this.teachersId.push(element.user_id);
              this.teachersData.push({
                user_id: element.user_id,
                teacher_full_name:
                  element.teacherInformation?.first_name +
                  element.teacherInformation?.last_name,
                teacher_code:
                  element.teacherInformation?.first_name[0] +
                  element.teacherInformation?.last_name[0],
              });
            });
          } else {
            this.teachersData = [];
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.$refs.mdlLoader.loadingDialog = false;
          this.dialog = true;
        });
    },
    updateAcademicGroup() {
      this.isLoading = true;
      teacherRepository
        .addTeacherToAcademicGroup(this.academicGroups.id, {
          user_ids: this.savedData,
        })
        .then(({ data }) => {
          this.sweetAlertResponse({
            status: data.status,
            message: data.message,
          });
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.closeModal();
        });
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },
    closeModal() {
      this.toggleModal();
      this.savedData = [];
      this.teachersData = [];
      this.assignTeacher = {};
      this.teachersId = [];
      this.search = "";
      this.verifyTechnical = "";
      this.verifyAcademic = "";
      this.$emit("clean-academic");
      this.$emit("clean-technical");
    },
    save() {
      if (this.teachersData.length > 0) {
        this.teachersData.forEach((element) => {
          this.savedData.push(element.user_id);
        });
        if (!this.verifyTechnical) {
          this.updateTechnicalGroup();
        } else if (!this.verifyAcademic) {
          this.updateAcademicGroup();
        }
      } else {
        this.fireToast({
          icon: "error",
          title: "Tiene que seleccionar al menos un maestro",
        });
      }
    },
    teacherData(id) {
      return this.teachers.find((item) => item.id_user == id);
    },
    hasBeenSelected(id) {
      return this.teachersId.indexOf(id) > -1;
    },
    addTeacher({ id_user, first_name, last_name }) {
      this.teachersId.push(id_user);
      this.teachersData.push({
        user_id: id_user,
        teacher_full_name: `${first_name} ${last_name}`,
        teacher_code: first_name[0] + last_name[0],
      });
    },

    removeTeacher(id) {
      this.teachersId = this.teachersId.filter((item) => item != id);
      this.teachersData = this.teachersData.filter(
        (item) => item.user_id != id
      );
    },
  },
  computed: {
    teachers() {
      let userTypes = [];
      if (process.env.APP_ENV === "local") {
        userTypes.push("Superadministrador");
        userTypes.push("Superadmin");
      } else {
        userTypes.push("Docente académico");
        userTypes.push("Docente técnico");
      }
      return this.$store.getters.getUsersByUserType(userTypes);
    },

    filteredTeachers() {
      return this.teachers.filter(
        (item) =>
          item.first_name
            .toLowerCase()
            .includes(this.search.toLowerCase().trim()) ||
          item.last_name
            .toLowerCase()
            .includes(this.search.toLowerCase().trim())
      );
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.verifyTechnical =
          Object.entries(this.technicalGroups).length === 0;
        this.verifyAcademic = Object.entries(this.academicGroups).length === 0;
        if (!this.verifyTechnical) {
          this.assignTeacher = this.technicalGroups;
        } else if (!this.verifyAcademic) {
          this.assignTeacher = this.academicGroups;
        }
        this.$store.dispatch(FETCH_USERS);
      }
    },
  },
};
</script>
