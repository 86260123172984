import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @param id
 * @returns {*}
 */
export const getGuideTeacherByTechnicalGroup = (id) => {
  return ApiService.get("technical_groups/teachers-guide", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @param payload
 * @returns {*}
 */
export const addTeacherToTechnicalGroup = (id, payload) => {
  return ApiService.update("technical_groups/teachers-guide", id, payload);
};

/**
 * GET request to fetch all data
 * @param id
 * @returns {*}
 */
export const getGuideTeacherByAcademicGroup = (id) => {
  return ApiService.get("academic_groups/teachers-guide", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @param payload
 * @returns {*}
 */
export const addTeacherToAcademicGroup = (id, payload) => {
  return ApiService.update("academic_groups/teachers-guide", id, payload);
};

export default {
  getGuideTeacherByTechnicalGroup,
  addTeacherToTechnicalGroup,
  getGuideTeacherByAcademicGroup,
  addTeacherToAcademicGroup,
};
