<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="850"
      persistent
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="blue darken-2" class="elevation-0 px-3">
            <span class="headline">Grupos académicos</span>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <!-- begin::Grade info -->
              <v-row>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Grado:</div>
                    <span class="text-h6 font-weight-medium">{{
                      grade.name
                    }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="black--text mb-1 flex-column">
                    <div class="text-body-2">Nivel académico:</div>
                    <span class="text-h6 font-weight-medium">{{
                      grade.academic_level ? grade.academic_level.name : ""
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <!-- end::Grade info -->
            </v-container>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-body-1"
                    >Selecciona la sección a asignar</span
                  >
                </v-col>
              </v-row>
              <!-- Begin::Academic Groups -->
              <v-row v-if="Object.keys(gradeAcademicGroups) != 0">
                <v-col class="pb-0">
                  <span class="text-h6">Grupos académicos</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-list
                    v-for="(academicGroups, section) in gradeAcademicGroups"
                    dense
                    :key="`section-${section}`"
                    class="scrollable-list"
                  >
                    <span class="text-body-2">Secciones "{{ section }}"</span>
                    <v-list-item-group color="blue darken-2">
                      <v-list-item
                        v-for="academicGroup in academicGroups"
                        :key="`aG-${academicGroup.id}`"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="academicGroup.name"
                            class="text-body-1"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            depressed
                            small
                            @click="
                              selectAcademicGroup(academicGroup);
                              showMdlSelectTeacherGuideModal();
                            "
                          >
                            Ver
                            <v-icon color="blue darken-2" right>
                              mdi-eye-outline
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
              <!-- End::Academic Groups -->
              <!-- Begin::TechnicalGroup -->
              <v-row v-if="Object.keys(technicalGroups) != 0">
                <v-col class="pb-0">
                  <span class="text-h6">Grupos Técnicos</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-list
                    v-for="(technicalGroup, speciality) in technicalGroups"
                    dense
                    :key="`section-${speciality}`"
                    class="scrollable-list"
                  >
                    <span class="text-body-2"
                      >Especialidad {{ speciality }}</span
                    >
                    <v-list-item-group color="blue darken-2">
                      <v-list-item
                        v-for="group in technicalGroup"
                        :key="`aG-${group.id}`"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="group.name"
                            class="text-body-1"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            depressed
                            small
                            @click="
                              selectTechnicalGroup(group);
                              showMdlSelectTeacherGuideModal();
                            "
                          >
                            Ver
                            <v-icon color="blue darken-2" right>
                              mdi-eye-outline
                            </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
              <!-- End::TechnicalGroup -->
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn text outlined class="mr-1" @click="closeModal()"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
        <SelectTeacherGuide
          ref="mdlSelectTeacherGuideModal"
          :academicGroups="selectedAcademicGroup"
          :technicalGroups="selectedTechnicalGroup"
          @clean-academic="unSelectAcademicGroup"
          @clean-technical="unSelectTechnicalGroup"
        ></SelectTeacherGuide>
      </v-form>
    </v-dialog>
    <LoaderModal ref="mdlLoader">
      <template v-slot:title>
        Cargando grupos técnicos y académicos...</template
      >
    </LoaderModal>
  </div>
</template>
<script>
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import SelectTeacherGuide from "@/components/modals/grades/SelectTeacherGuide.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";

export default {
  name: "ManageGuideTeacherModal",
  components: {
    SelectTeacherGuide,
    LoaderModal,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      gradeAcademicGroups: [],
      technicalGroups: [],
      teachersData: [],
      selectedAcademicGroup: {},
      selectedTechnicalGroup: {},
      loadingDialog: false,
    };
  },
  props: {
    grade: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.technicalGroups = [];
      this.gradeAcademicGroups = [];
      this.$emit("clean-selection");
    },
    showMdlSelectTeacherGuideModal() {
      this.verifyTechnical =
        Object.entries(this.selectedTechnicalGroup).length === 0;
      this.verifyAcademic =
        Object.entries(this.selectedAcademicGroup).length === 0;
      if (!this.verifyTechnical) {
        this.$refs.mdlSelectTeacherGuideModal.getTechnicalGroup(
          this.selectedTechnicalGroup.id
        );
      } else if (!this.verifyAcademic) {
        this.$refs.mdlSelectTeacherGuideModal.getAcademicGroup(
          this.selectedAcademicGroup.id
        );
      }
    },
    getAcademicGroupsOfGrade(id) {
      this.$refs.mdlLoader.loadingDialog = true;
      academicGroupRepository
        .getAcademicGroupsByGrade(id)
        .then(({ data }) => {
          // Agrupa los grupos academicos por secciones en un objecto donde la key es la sección y el valor es un array con todos los grupos academicos
          const dataGroupedBySection = data.reduce((accumulator, current) => {
            if (!accumulator[current.section]) {
              accumulator[current.section] = [];
            }
            accumulator[current.section].push({
              id: current.id,
              name: `${current.section}-${current.group}`,
            });
            return accumulator;
          }, {});
          this.gradeAcademicGroups = dataGroupedBySection;
          this.getTechnicalGroupsOfGrade(id);
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getTechnicalGroupsOfGrade(id) {
      technicalGroupRepository
        .getTechnicalGroupsByGrade(id)
        .then(({ data }) => {
          const dataGroupedBySpeciality = data.reduce(
            (accumulator, current) => {
              if (!accumulator[current.speciality.name]) {
                accumulator[current.speciality.name] = [];
              }
              accumulator[current.speciality.name].push({
                id: current.id,
                name: `${current.grade_speciality_group_formatted}`,
                speciality: `${current.speciality.name}`,
              });
              return accumulator;
            },
            {}
          );
          this.technicalGroups = dataGroupedBySpeciality;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.$refs.mdlLoader.loadingDialog = false;
          this.toggleModal();
        });
    },
    selectAcademicGroup(academicGroup) {
      this.selectedAcademicGroup = { ...academicGroup };
    },
    selectTechnicalGroup(technicalGroup) {
      this.selectedTechnicalGroup = { ...technicalGroup };
    },
    unSelectAcademicGroup() {
      this.selectedAcademicGroup = {};
    },
    unSelectTechnicalGroup() {
      this.selectedTechnicalGroup = {};
    },
  },
};
</script>
<style>
.scrollable-list {
  overflow-y: auto;
}
</style>
