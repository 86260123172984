<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de grados
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar, eliminar y cambiar
              el estado de los grados.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg
            ></span>
            Crear grado
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="grades.search"
                  ></v-text-field>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        academicLevels.isLoading
                          ? 'Cargando niveles académicos'
                          : 'Nivel académico'
                      "
                      :loading="academicLevels.isLoading"
                      :disabled="!academicLevels.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="academicLevels.data"
                      item-text="name"
                      item-value="id"
                      v-model="grades.filters.academic_level_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ grades.filters.academic_level_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="grades.headers"
            :items="filteredData"
            :search="grades.search"
            :items-per-page="10"
            :loading="grades.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectGrade(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectGrade(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template
                v-if="existInArray('Activar/Desactivar', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectGrade(item);
                        showMdlChangeStatus();
                      "
                      :color="
                        item.status == 1
                          ? 'cyan darken-3'
                          : 'blue-grey darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status == 1
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{ item.status == 1 ? "Desactivar" : "Activar" }}
                  </span>
                </v-tooltip>
              </template>
              <template
                v-if="
                  existInArray(
                    'Gestionar grupos académicos',
                    currentPageActions
                  )
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectGrade(item);
                        showMdlAcademicGroups();
                      "
                      color="deep-purple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-group
                    </v-icon>
                  </template>
                  <span>Gestionar grupos académicos</span>
                </v-tooltip>
              </template>
              <template
                v-if="
                  item.academic_level.has_technical_groups &&
                    existInArray(
                      'Gestionar grupos técnicos',
                      currentPageActions
                    )
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectGrade(item);
                        showMdlTechnicalGroups();
                      "
                      color="light-green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-group
                    </v-icon>
                  </template>
                  <span>Gestionar grupos técnicos</span>
                </v-tooltip>
              </template>
              <!-- Acá esta la nueva funcionalidad -->
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectGrade(item);
                        showMdlManageGuideTeacherModal();
                      "
                      color="blue darken-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-cog
                    </v-icon>
                  </template>
                  <span>Gestionar docentes guías</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateGradeModal
      ref="mdlCreate"
      :academicLevels="academicLevels.data"
      @reload-data="loadGrades"
    ></CreateGradeModal>

    <UpdateGradeModal
      ref="mdlUpdate"
      @reload-data="loadGrades"
      @clean-slection="unselectGrade"
      :grade="selectedGrade"
      :academicLevels="academicLevels.data"
    ></UpdateGradeModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteGrade">
      <template v-slot:title>¿Desea eliminar este grado?</template>
      <template v-slot:subject>
        el grado
        <span class="font-weight-bolder">{{ selectedGrade.name }}</span>
      </template>
    </DeleteBaseModal>

    <ChangeStatusBaseModal
      ref="mdlChangeStatus"
      :selected="selectedGrade"
      @change-status="changeStatusGrade"
    >
      <template v-slot:title>
        ¿Deseas cambiar el estado de este grado?
      </template>
      <template v-slot:subject>
        el grado
        <span class="font-weight-bolder">{{ selectedGrade.name }}</span>
      </template>
    </ChangeStatusBaseModal>

    <ManageTechnicalGroupsModal ref="mdlTechnicalGroups" :grade="selectedGrade">
    </ManageTechnicalGroupsModal>

    <ManageAcademicGroupsModal ref="mdlAcademicGroups" :grade="selectedGrade">
    </ManageAcademicGroupsModal>
    <ManageGuideTeacherModal
      ref="mdlManageGuideTeacherModal"
      :grade="selectedGrade"
      @clean-selection="unselectGrade"
    ></ManageGuideTeacherModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import gradeRepository from "@/repositories/gradeRepository";
import academicLevelRepository from "@/repositories/academicLevelRepository";
import CreateGradeModal from "@/components/modals/grades/CreateGradeModal.vue";
import UpdateGradeModal from "@/components/modals/grades/UpdateGradeModal.vue";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import ManageTechnicalGroupsModal from "@/components/modals/grades/ManageTechnicalGroupsModal.vue";
import ManageAcademicGroupsModal from "@/components/modals/grades/ManageAcademicGroupsModal.vue";
import ManageGuideTeacherModal from "@/components/modals/grades/ManageGuideTeacherModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "Grades",
  title: "Grados | GE ITR",
  components: {
    CreateGradeModal,
    UpdateGradeModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
    ManageTechnicalGroupsModal,
    ManageAcademicGroupsModal,
    ManageGuideTeacherModal,
  },
  data() {
    return {
      grades: {
        headers: [
          { text: "Grado", value: "name" },
          { text: "Nivel académico", value: "academic_level.name" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      selectedGrade: {},
      academicLevels: {
        data: [],
        isLoading: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Grados", route: "grades" },
    ]);
    this.loadGrades();
    this.getAcademicLevels();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Grados");
  },
  methods: {
    selectGrade(obj) {
      // Creating a non reactive copie of data
      this.selectedGrade = { ...obj };
    },
    unselectGrade() {
      this.selectedGrade = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showMdlChangeStatus() {
      this.$refs.mdlChangeStatus.toggleModal();
    },
    showMdlTechnicalGroups() {
      this.$refs.mdlTechnicalGroups.toggleModal();
    },
    showMdlAcademicGroups() {
      this.$refs.mdlAcademicGroups.toggleModal();
    },
    showMdlManageGuideTeacherModal() {
      this.$refs.mdlManageGuideTeacherModal.getAcademicGroupsOfGrade(
        this.selectedGrade.id
      );
    },
    loadGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },
    deleteGrade() {
      gradeRepository
        .deleteGrade(this.selectedGrade.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadGrades();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro ${this.selectedGrade.name} en estos momentos 🙁`,
          });
        });
    },
    changeStatusGrade() {
      gradeRepository
        .changeStatusGrade(this.selectedGrade.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadGrades();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible cambiar el estado del registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    getAcademicLevels() {
      this.academicLevels.isLoading = true;
      academicLevelRepository
        .getAllAcademicLevels()
        .then(({ data }) => {
          this.academicLevels.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.academicLevels.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.grades.filters, this.grades.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
