import { render, staticRenderFns } from "./ManageGuideTeacherModal.vue?vue&type=template&id=16dc2732"
import script from "./ManageGuideTeacherModal.vue?vue&type=script&lang=js"
export * from "./ManageGuideTeacherModal.vue?vue&type=script&lang=js"
import style0 from "./ManageGuideTeacherModal.vue?vue&type=style&index=0&id=16dc2732&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports