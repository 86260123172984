<template>
  <v-dialog v-model="loadingDialog" hide-overlay persistent width="400">
    <v-card color="blue darken-2" class="pa-5">
      <div class="d-flex flex-column">
        <p class="text-h6 font-weight-bold text-center white--text">
          <slot name="title"></slot>
        </p>
      </div>
      <v-card-text>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      loadingDialog: false,
    };
  },
};
</script>
