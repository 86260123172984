<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="deep-purple" class="elevation-0 px-3">
          <span class="headline">Gestionar grupos académicos</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <!-- begin::Grade info -->
            <v-row>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Grado:</div>
                  <span class="text-h6 font-weight-medium">{{
                    grade.name
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Nivel académico:</div>
                  <span class="text-h6 font-weight-medium">{{
                    grade.academic_level ? grade.academic_level.name : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- end::Grade info -->
          </v-container>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <span class="text-body-1"
                  >Selecciona las secciones que conformarán el grado</span
                >
              </v-col>
            </v-row>
            <!-- begin::ALerta informativa -->
            <v-alert
              v-if="sections.length == 0"
              dense
              text
              type="info"
              class="mt-2"
            >
              No hay ninguna sección registrada, asegurate de registrarlas en el
              apartado de secciones y posteriormente agregar grupos académicos
              por cada una.
            </v-alert>
            <!-- end::ALerta informativa -->
            <!-- begin::Stage Types List with their Stages -->
            <v-row v-for="section in sections" :key="section.id" v-else>
              <v-col>
                <h2 class="text-subtitle-1 font-weight-bold mb-1">
                  {{ `Sección "${section.name}"` }}
                </h2>
                <!-- begin::ALerta informativa -->
                <v-alert
                  v-if="section.section_groups.length == 0"
                  dense
                  text
                  type="info"
                  class="my-0"
                >
                  No hay ningún grupo académico registrado en esta sección,
                  asegurate de crearlos en el apartado de secciones.
                </v-alert>
                <!-- end::ALerta informativa -->
                <!-- begin::Options -->
                <v-chip-group
                  v-model="section_groups"
                  column
                  multiple
                  active-class="deep-purple--text"
                  v-else
                >
                  <v-chip
                    filter
                    outlined
                    v-for="item in section.section_groups"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.section_group }}
                  </v-chip>
                </v-chip-group>
                <!-- end::Options -->
              </v-col>
            </v-row>
            <!-- end::Stage Types List with their Stages -->
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="deep-purple"
            dark
            depressed
            :loading="isLoading"
            @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import academicGroupRepository from "@/repositories/academicGroupRepository";
import sectionRepository from "@/repositories/sectionRepository";

export default {
  name: "ManageAcademicGroupsModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      section_groups: [],
      sections: [],
    };
  },
  props: {
    grade: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.section_groups = [];
      this.sections = [];
    },

    save() {
      this.isLoading = true;
      const obj = {
        grade_id: this.grade.id,
        section_groups: this.section_groups,
      };
      academicGroupRepository
        .saveGradeAcademicGroups(obj)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
            this.section_groups = [];
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAcademicGroupsOfGrade(id) {
      academicGroupRepository
        .getAcademicGroupsByGrade(id)
        .then(({ data }) => {
          data.forEach((item) => {
            this.section_groups.push(item.section_group_id);
          });
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getSections() {
      sectionRepository
        .getAllSectionWithSectionGroups()
        .then(({ data }) => {
          this.sections = data.map((item) => {
            const obj = {
              id: item.id,
              name: item.name,
              section_groups: item.section_groups.sort(
                this.sortByProperty("section_group")
              ),
            };
            return obj;
          });
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.getSections();
        this.getAcademicGroupsOfGrade(this.grade.id);
      }
    },
  },
};
</script>
